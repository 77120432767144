import logo from './tora-logo.svg';
import './App.css';
import bg from './bg.png'


function App() {
  return (
    <div className="App" style={{ background: `url(${bg}) center center/cover no-repeat` }}>
      <div className='flex'>
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Coming Soon</h1>
        <p>
          Something delicious <br /> is cooking for you!
        </p>
      </div>
    </div>
  );
}

export default App;
